import packageJson from '../../package.json';

export function setCookie(name: string, value: string, expirationDays: number) {
  const date = new Date();
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}
export function getCookie(name: string) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }
  return null;
}

export function clearCookiesAndCache() {
  document.cookie.split(';').forEach(cookie => {
    const cookieName = cookie.split('=')[0].trim();
    // Clear the cookie by setting its expiration date in the past and setting path to root
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });

  // Clear cache (localStorage and sessionStorage)
  localStorage.clear();
  sessionStorage.clear();
}

export function checkVersionAndClearCache() {
  const storedVersion = localStorage.getItem('app_version');
  const currentVersion = packageJson.version;

  if (storedVersion !== currentVersion) {
    clearCookiesAndCache();
    localStorage.setItem('app_version', currentVersion); // Update to the new version
  }
}

import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import { IAbstractTab } from 'types/common';
import { WithPartyId } from 'app/hooks/useGetAbstractData/typesParamsConfig';

export interface PayloadGetFilesConfig extends WithPartyId {}

export type FilesTabType = 'evidence' | 'questionnaire' | 'contracts';

export interface IFilesTab extends IAbstractTab<FilesTabType> {
  can_upload_files?: boolean;
  accept?: string[];
}

export interface ResponseGetFilesConfig {
  tabs: IFilesTab[];
}

const mock: ResponseGetFilesConfig = {
  tabs: [
    {
      id: 'evidence',
      label: 'Evidence',
      can_upload_files: true,
      accept: ['.txt', '.pdf', '.doc', '.docx'],
    },
    {
      id: 'questionnaire',
      label: 'Questionnaire',
      can_upload_files: true,
      accept: ['.txt', '.pdf', '.doc', '.docx'],
    },
    { id: 'contracts', label: 'Contracts' },
  ],
};

export const getFilesConfigAPI: RequestApi<
  PayloadGetFilesConfig,
  ResponseGetFilesConfig
> = async ({ thirdPartyId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/document_vault/settings/${thirdPartyId}`, { signal });
};

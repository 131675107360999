import { RiskHunterSearchResultTypes } from 'app/containers/RootAggregationRisk/PageAggregationRiskSearch/types';
import { DominoDimensionType, RelationType } from 'types/domino';
import { PlaceType } from 'app/hooks/useGetLocations/types';

export const numberWithCommas = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getBase64 = async (file: File): Promise<unknown> => {
  return new Promise((resolve: any, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export function lerpColor(a, b, amount) {
  const ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return `#${(((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0)
    .toString(16)
    .slice(1)}`;
}

export const getHex = size =>
  [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');

declare global {
  /*~ Here, declare things that go in the global namespace, or augment
   *~ existing declarations in the global namespace
   */

  interface String {
    capitalize(): String;
    interpolate(params: any): String;
  }
}

if (!String.prototype.capitalize) {
  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
}

if (!String.prototype.interpolate) {
  // eslint-disable-next-line no-extend-native
  String.prototype.interpolate = function (params: any) {
    const names = Object.keys(params);
    const values = Object.values(params);
    // eslint-disable-next-line no-new-func
    return new Function(...names, `return \`${this}\`;`)(...values);
  };
}

export const engAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const marshalRiskHunterTypeForGA = (
  type: RiskHunterSearchResultTypes,
) => {
  switch (type) {
    case 'party':
      return 'Third Party';
    case 'cve':
      return 'CVE';
    case 'location':
      return 'Location';
    case 'person':
      return 'Person';
    case 'rm':
      return 'Relationship Manager';
    case 'software_package':
      return 'Software package';
    default:
      return undefined;
  }
};

export const marshalRelationItemTypeForGA = (type: DominoDimensionType) => {
  switch (type) {
    case 'party':
    case 'party.tp':
      return 'Third Party';
    case 'party.fp':
      return 'Fourth Part';
    case 'cve':
      return 'CVE';
    case 'location':
      return 'Location';
    case 'person':
      return 'Person';
    case 'rm':
      return 'Relationship Manager';
    case 'business_unit':
      return 'Business Unit';
    case 'risk_domain':
      return 'Risk Domain';
    case 'rel_a_and_b':
      return 'Relation A and B';
    case 'rel_a_and_not_b':
      return 'Relation A and not B';
    case 'rel_not_a_and_b':
      return 'Relation not A and B';
    case 'software_package':
      return 'Software package';
    default:
      return undefined;
  }
};

export const marshalRelationTypeForGA = (type: RelationType) => {
  switch (type) {
    case 'rel_a_and_b':
      return 'Relation A and B';
    case 'rel_a_and_not_b':
      return 'Relation A and not B';
    case 'rel_not_a_and_b':
      return 'Relation not A and B';
    default:
      return undefined;
  }
};

export const generateRandomId = function (length = 6) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};

export const validateLocation = (value: string, locations: PlaceType[]) =>
  locations.find(location => location.description.includes(value));

export function getFileExtension(filename: string): string | null {
  const lastDotIndex = filename.lastIndexOf('.');
  if (
    lastDotIndex === -1 ||
    lastDotIndex === 0 ||
    lastDotIndex === filename.length - 1
  ) {
    return null; // No valid extension found
  }
  return filename.substring(lastDotIndex + 1).toLowerCase();
}
